import { Helmet } from "react-helmet";
import Footer from "../../components/dark/modern-agency/Footer";
import Lines from "../../components/dark/common/Lines";
import ProgressScroll from "../../components/dark/common/ProgressScroll";
import Cursor from "../../components/dark/common/cusor";
import LoadingScreen from "../../components/dark/common/loader";
import Navbar from "../../components/dark/creative-agency/Navbar";
import Testimonials from "../../components/dark/FAQS/Testimonials";
import Header from "../../components/dark/services/Header";
import Services from "../../components/dark/services/Services";
import Marquee from "../../components/dark/services/Marquee";
import Price from "../../components/dark/services/Price";
import Process from "../../components/dark/services/Process";
import Blog from "../../components/dark/services/Blog";
export default function PageServices() {
  return (
    <>
      <Helmet>
        <title>infolio</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <Navbar />
        <Header />
        <Services />
        <Marquee />
        <Price />
        <Testimonials />
        <Process />
        <Blog />
        <Footer />
      </body>
    </>
  );
}
