import React, { useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const AppointmentComp = ({ toggleModal }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  const options = [
    { value: "Consultation", label: "Consultation" },
    { value: "Pediatric_Consultation", label: "Pediatric Treatment" },
    { value: "Myobrace_Consultation", label: "Myobrace Treatment" },
    { value: "Dental_Implants", label: "Dental Implants" },
    { value: "Habit_Breaking_Appliance", label: "Habit Breaking Appliance" },
    { value: "Invisalign_treatments", label: "Invisalign Treatments" },
    { value: "Wisdom_tooth_removal", label: "Wisdom Tooth Removal" },
    { value: "Ortho-adjustment", label: "Orthodontic Treatments" },
    { value: "Teeth-whitening", label: "Teeth Whitening" },
    { value: "Teeth-cleaning", label: "Teeth Cleaning" },
    { value: "Teeth-extraction", label: "Teeth Extraction" },
    { value: "Quality-brackets", label: "Quality Brackets" },
    { value: "Other-Treatment", label: "Other Treatment" },
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const modalContentClass = {
    ...customStyles,
    content: {
      ...customStyles.content,
      animation: "fadeIn 0.5s ease-in-out",
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    console.log("ites working");

    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="Appointment-wrap">
        <Link to="#" onClick={toggleModal}>
          <img
            src="/dark/assets/imgs/icon-img/appointment.png"
            alt="Whatsapp"
          />
        </Link>
      </div>
    </>
  );
};

export default AppointmentComp;
