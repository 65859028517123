import React, { useEffect } from "react";

function OfferComponent({ content }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <section className="about">
        <div className="container section-padding bord-thin-top">
          <div className="row md-marg align-items-center justify-content-center">
            <div className="col-lg-4 offset-lg-1">
              <div
                className="cont md-mb50"
                style={{ border: "1px solid #ccc", padding: "1.5em" }}
              >
                <h6
                  className="sub-title  mb-15 text-center"
                  style={{ color: "#FFF" }}
                >
                  In-House Dental Membership plan <br />
                  $299/Yers
                </h6>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cont md-mb50">
                <h6 className="sub-title main-color mb-15 text-center">
                  Title Here
                </h6>
                <div>
                  <p className="text-center">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OfferComponent;
