import React from "react";

function Services({ reasons }) {
  function getPlainText(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  return (
    <section className="skills-img-crev pb-50 pt-50">
      <div className="container">
        <div className="sec-head ">
          <h6 className="sub-title mb-15 main-color">Why Choose Us</h6>
          <div className="bord bord-thin-top d-flex align-items-center"></div>
        </div>
        {reasons &&
          reasons?.map((ele, index) => {
            return (
              <div
                className="row justify-content-between pt-50 mt-50"
                key={index}
              >
                <div className="col-lg-5 valign">
                  <div className="img md-mb80">
                    <img src={ele?.image} alt="" className="radius-30" />
                  </div>
                </div>
                <div className="col-lg-6 valign">
                  <div className="content full-width">
                    <div className="sec-head mb-25">
                      <h5 className="d-slideup wow">
                        <span className="sideup-text">
                          <span className="up-text">{ele?.title}</span>
                        </span>
                      </h5>
                      {ele?.short_desc && (
                        <h4 className="d-slideup wow">
                          <span className="sideup-text">
                            <span className="up-text">{ele?.short_desc}</span>
                          </span>
                        </h4>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-11">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: ele?.long_desc }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
}

export default Services;
