import React, { useRef, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToastNotification from "../../../Utils/ToastNotification";
import axios from "axios";
import { APPOINTMENT_API, BASE_URL } from "../../../app/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "400px",
    zIndex: 99,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// Set the root element for accessibility
Modal.setAppElement("#root");

function AppointmentModal({ isOpen, onClose }) {
  const subtitleRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");

  const options = [
    { value: "Consultation", label: "Consultation" },
    { value: "Pediatric_Consultation", label: "Pediatric Treatment" },
    { value: "Myobrace_Consultation", label: "Myobrace Treatment" },
    { value: "Dental_Implants", label: "Dental Implants" },
    { value: "Habit_Breaking_Appliance", label: "Habit Breaking Appliance" },
    { value: "Invisalign_treatments", label: "Invisalign Treatments" },
    { value: "Wisdom_tooth_removal", label: "Wisdom Tooth Removal" },
    { value: "Ortho-adjustment", label: "Orthodontic Treatments" },
    { value: "Teeth-whitening", label: "Teeth Whitening" },
    { value: "Teeth-cleaning", label: "Teeth Cleaning" },
    { value: "Teeth-extraction", label: "Teeth Extraction" },
    { value: "Quality-brackets", label: "Quality Brackets" },
    { value: "Other-Treatment", label: "Other Treatment" },
  ];

  const afterOpenModal = () => {
    if (subtitleRef.current) {
      subtitleRef.current.style.color = "#2f89fc";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !selectedCountry?.value || !mobileNo || !email) {
      ToastNotification("error", "Please fill all the fields!");
      return;
    }

    setLoading(true);

    const appointmentData = {
      name,
      appointment_for: selectedCountry?.value || "",
      mobile_no: mobileNo,
      email,
      date: startDate.toISOString().split("T")[0],
      time,
      message,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}${APPOINTMENT_API}`,
        appointmentData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        ToastNotification("success", "Appointment successfully made!");
        setName("");
        setSelectedCountry(null);
        setMobileNo("");
        setEmail("");
        setStartDate(new Date());
        setMessage("");
        setTime("");
      } else {
        ToastNotification("error", "Failed to make appointment.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastNotification("error", "Failed to make appointment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Appointment Modal"
    >
      <section className="login-register-form-section">
        <div className="container login-register">
          <div className="user signupBx">
            <div className="formBx">
              <form onSubmit={handleSubmit}>
                <h2>Make an Appointment</h2>
                <input
                  style={{
                    border: "1px solid #ccc",
                  }}
                  type="text"
                  name=""
                  placeholder="Name *"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <div>
                  <Select
                    className="mb--10 border-0 bg-red"
                    options={options}
                    onChange={setSelectedCountry}
                    placeholder="Appointment For *"
                    isSearchable
                    value={selectedCountry}
                    styles={{
                      option: (base) => ({
                        ...base,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <input
                  style={{
                    border: "1px solid #ccc",
                  }}
                  type="text"
                  name=""
                  placeholder="Mobile No. *"
                  autoComplete="off"
                  onChange={(e) => setMobileNo(e.target.value)}
                  value={mobileNo}
                />
                <input
                  style={{
                    border: "1px solid #ccc",
                  }}
                  type="email"
                  name=""
                  placeholder="Email Address *"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="datepickerContainer">
                    <DatePicker
                      style={{
                        border: "none",
                      }}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="timepickerContainer">
                    <input
                      onChange={(e) => setTime(e.target.value)}
                      style={{
                        border: "1px solid #ccc",
                      }}
                      aria-label="Time"
                      type="time"
                    />
                  </div>
                </div>
                <textarea
                  name=""
                  placeholder="Message"
                  rows={6}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button
                  style={{
                    background: "#2f89fc",
                    border: "1px solid #2f89fc",
                    color: "#fff",
                    padding: "8px",
                    width: "100%",
                  }}
                >
                  Make an Appointment
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default AppointmentModal;
