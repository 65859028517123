import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Intro from "../../components/dark/about2/Intro";
import Numbers from "../../components/dark/about2/Numbers";
import About from "../../components/dark/about2/About";
import Portfolio from "../../components/dark/creative-agency/Portfolio";
import Testimonials from "../../components/dark/home-main/Testimonials";
import { useEffect } from "react";
import Header from "../../components/dark/about2/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutUsData } from "../../app/features/aboutUsDataSlice";
import DynamicHelmate from "../../components/dark/Helmet/DynamicHelmate";

export default function PageAbout2() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAboutUsData());
  }, [dispatch]);

  const content = useSelector((state) => state.aboutUsData.content);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <DynamicHelmate metacontent={content} />
      <body>
        {/* <Intro title="About Us" /> */}
        <Header />
        <Numbers content={content} />
        <About content={content} />
        <Portfolio />
        <Footer />
      </body>
    </>
  );
}
