import React, { useState, useEffect } from "react";
import axios from "axios";
import ToastNotification from "../../../Utils/ToastNotification";
import { BASE_URL, CONTACT_API } from "../../../app/api";
import { FileUploader } from "react-drag-drop-files";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./carr.css";

function CareersComponent({ businessInfo }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const fileTypes = ["JPG", "PNG", "PDF"];

  const handleChange = (file) => {
    // setFile(file);
  };
  const handleFileChange = (file) => {
    setFile(file);
  };
  const hireNowData = [
    {
      title: "GENERAL DENTISTS",
      des: ["With 5 years of experience", "Holds an active DHA license"],
    },
    {
      title: "DENTAL HYGIENISTS",
      des: ["With 5 years of experience", "Holds an active DHA license"],
    },
    {
      title: "RECEPTIONISTS",
      des: ["With 2 years of experience"],
    },
    {
      title: "DENTAL NURSES",
      des: ["With 2 years of experience", "Holds an active DHA license"],
    },
  ];
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    // Extract titles and set the state
    const extractedTitles = hireNowData.map((item) => item.title);
    setTitles(extractedTitles);
  }, []); // Empty dependency array ensures it runs once when the component mounts
  //   const options = ["Apply For", "two", "three"];

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!name || !phone || !email || !subject || !message) {
      alert("Please fill all required fields.");
      return;
    }

    const contactData = { name, phone, email, subject, message };

    try {
      const response = await axios.post(BASE_URL + CONTACT_API, contactData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        ToastNotification("success", "Message sent successfully!");
        // Clear the form fields
        setName("");
        setPhone("");
        setEmail("");
        setSubject("");
        setMessage("");
      } else {
        ToastNotification("error", "Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastNotification("error", "An error occurred. Please try again later.");
    }
  };

  return (
    <section className="contact section-padding sub-bg">
      <div className="container">
        <h2 className="text-u ls1 d-rotate wow">
          <span className="rotate-text text-center mb-100">
            Send us your updated CV and be a part of the best dental team in the
            UAE.
          </span>
        </h2>
        <div className="row">
          <div className="col-lg-7 valign">
            <div className="full-width">
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="controls row">
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        placeholder="Name *"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_phone"
                        type="tel"
                        name="phone"
                        placeholder="Phone *"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-30">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        placeholder="Email *"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-30">
                      <Dropdown
                        options={titles}
                        handleChange={handleChange}
                        value={titles[0]}
                        placeholder="Apply For"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-30">
                      <FileUploader
                        handleChange={handleFileChange}
                        name="CV"
                        dropMessageStyle={{ backgroundColor: "red" }}
                        types={fileTypes}
                        uploadedLabel={`(${file?.name}) Uploaded Successfully!  `}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-30">
                      <button
                        type="submit"
                        className="butn butn-full butn-bord radius-30"
                      >
                        <span className="text">Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-lg-4 offset-lg-1 valign"
            style={{
              backgroundColor: "#222",
              padding: "20px 20px",
            }}
          >
            <div style={{ width: "100%" }}>
              <h5 className="text-u ls1 d-rotate wow underline w-100 text-center">
                <span className="rotate-text mb-30">HIRING NOW</span>
              </h5>
              {hireNowData?.map((ele, index) => {
                return (
                  <div key={index} className="mb-30">
                    <h5 style={{ fontSize: "1.2rem" }}>{ele?.title}</h5>
                    <ul>
                      {ele?.des?.map((val, id) => {
                        return <li key={id}>➵ {val}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersComponent;
